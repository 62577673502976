<template>
  <div class="container-fluid">
    <div class="row justify-content-end">
      <router-link :to="{ name: 'accounts.series.create' }" class="btn btn-xs btn-primary mg-b-15 float-right">{{ $t('series.create')}}</router-link>
    </div>
    <div class="placeholder-paragraph aligned-centered" v-if="loading">
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <div class="row" v-if="!loading">
      <table class="table">
        <thead>
        <tr>
          <!-- <th scope="col">Thumbnail</th> -->
          <th scope="col">Title</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in series" v-bind:key="item.id">
          <td>
            <router-link :to="{name: 'accounts.series.edit', params: {id: item.id}}">{{ item.title }}</router-link>
          </td>
          <td class="text-right">
            <fa :icon="['fa', 'trash-alt']" v-on:click="deleteSeries(item)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  
  export default {
    middleware: 'auth',
    data: () => ({
      loading: true
    }),
    computed: mapGetters({
      church: 'account/church',
      series: 'account/series'
    }),
    created() {
      this.$store.dispatch('account/fetchSeries')
        .then(() => { this.loading = false; });
    },
    methods: {
      async deleteSeries(series) {   
        try{
          this.loading = true;

          await axios.delete('/api/v1/user/me/series/' +  series.id);

          this.$store.dispatch('account/fetchSeries')
            .then(() => { this.loading = false; });
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      },
    }
  }
</script>
